import React from "react"
import { Grid, Box, Text, Button, Image } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
import LinkCard from "../components/LinkCard"

import professorPrimImage from "../images/spots/professor-prim.png"
import combinedImage from "../images/downloads/combined.png"
import npcImage from "../images/downloads/npc.png"
import worldProfileImage from "../images/downloads/world.png"

import combinedFile from "../downloads/combined-character-profile-and-worksheet.pdf"
import npcCharacterFile from "../downloads/npc-character-wheet.pdf"
import worldProfileFile from "../downloads/world-profile.pdf"
import questCreatorsLicenseAndResourceFile from "../downloads/quest-creators-license-and-resource.pdf"

const ResourceImage = ({src}) => {
  return (
    <Box
      sx={{
        height: [300, 400],
        mb: 4,
        border: "1px solid",
        borderColor: "grayLight",
        backgroundColor: "white",
        backgroundImage: `url(${src})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    />
  )
}

const ResourcesPage = () => (
  <Layout>
    <SEO title="Resources" />

    <Breadcrumbs title="Resources" headline="Start creating" />

    <Grid variant="textWrap">
      <Text as="p" variant="bodyLarge" sx={{ mb: [6, 8] }}>You only need a few things at the table to play Quest. Here, you can download copies of Quest’s worksheets to print at home and use in your game. To learn how to use these sheets, check out the Quest Game Book.</Text>
    </Grid>

    <Grid variant="base" columns={[1, 2]} sx={{ gridGap: 6 }}>
      <LinkCard
        title="Character Profile & Worksheet"
        image={
          <ResourceImage src={combinedImage} />
        }
        body="Use this sheet to create your character and track their resources. You can learn how to create your character in Chapter 2 of the Quest Game Book."
        buttonLabel="Download"
        download={combinedFile} />

      <LinkCard
        title="NPC Character Worksheet"
        image={
          <ResourceImage src={npcImage} />
        }
        body="You can use this sheet to quickly create non-player characters for the players to meet in your story."
        buttonLabel="Download"
        download={npcCharacterFile} />
      
      <LinkCard
        title="World Profile"
        image={
          <ResourceImage src={worldProfileImage} />
        }
        body="The world profile helps you create an exciting setting. Only The Guide needs to use the world profile. Follow the steps in Chapter 4 of the Quest Game book and fill in the blanks to create your own unique world."
        buttonLabel="Download"
        download={worldProfileFile} />
    </Grid>

    <Grid variant="base" gap={[4, 6, 8]} columns={[1, 1, "2fr 1fr"]} sx={{ pt: [4, 6], mt: [7, 8], bg: "grayXLight", alignItems: "center", overflowX: "visible" }}>
      <Box sx={{ pb: [4, 6] }}>
        <Text as="h2" variant="title02" sx={{ fontFamily: "display", textTransform: "none" }}>Quest Community Creators Resource</Text>
        <Text as="p" variant="body" sx={{ mb: 4 }}>Anyone can use our Community Creators Resource to create content for Quest. The resource is offered under a <Text as="a" variant="link" href="/license">Creative Commons license</Text> (CC-BY 4.0), allowing creators to make and sell their own content for the game.</Text>
        <a href={questCreatorsLicenseAndResourceFile} download>
          <Button variant="secondary">Download Resource (PDF)</Button>
        </a>
      </Box>
      
      <Image src={professorPrimImage} alt="Profressor Prim" sx={{ mb: [7, -7], mx: "auto", width: "100%", maxWidth: [300, "100%"] }} />
    </Grid>
  </Layout>
)

export default ResourcesPage